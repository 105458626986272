import './style.scss';

function Loader() {
  return (
    <>
      <div className="loader-wrapper">
        <span className="loader"></span>
      </div>
    </>
  );
}

export default Loader;